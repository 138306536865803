.project {
    width: 100%;
    height: calc(80vh);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .project h1 {
    margin-top: 50px;
    color: #e1be63;
    font-size: 35px;
  }
  .project h2 {
    margin-top: 50px;
    color: white;
    font-size: 25px;
    align-items: center;
    margin-left: calc(15px + (100vw - 700px) * (15 - 30) / (700 -  1000));
  }
  .image-container {
    display: flex;
  }
  .image-container img {
    flex: 1;
    max-width: 100%;
    margin-top: calc(15px + (100vw - 700px) * (15 - 30) / (700 -  1000)/8);;
    margin-right: calc(15px + (100vw - 700px) * (15 - 30) / (700 -  1000)/2);
    margin-left: calc(15px + (100vw - 700px) * (15 - 30) / (700 -  1000)/2);
    width: calc(100vw/5);
    border-radius: 10px;
  }

  
  .project p {
    font-size: 25px;
    align-items: center;
    margin-right: calc(15px + (100vw - 700px) * (15 - 30) / (700 -  1000)/2);
    margin-left: calc(15px + (100vw - 700px) * (15 - 30) / (700 -  1000)/2);
    color: white;
  }
  
  .project svg {
    font-size: 60px;
    color: white;
  }